<template>
  <div>
    <b-modal
        hide-footer
        id="modal-prevent-closing"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >

      <ModalAppointment v-if="id_apoi" :id_appoinment="id_apoi"/>

    </b-modal>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        >
          <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
            <div class="p-2">
              <router-link v-if="getUser.appointee_id" to="/nueva/consulta/doctor">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    aria-controls="sidebar-add-new-event"

                    variant="primary"
                    block

                >
                  Programar Cita
                </b-button>
              </router-link>
              <router-link v-else to="/nueva/consulta/recepcion">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    aria-controls="sidebar-add-new-event"

                    variant="primary"
                    block

                >
                  Agregar Cita
                </b-button>
              </router-link>
              <b-form-group label="Paciente" label-for="h-number">
                <b-form-input
                    @keyup="findCalendarPlacesSearchCompoPatient"
                    v-model="search_appointment"
                    id="colony"
                    placeholder="Paciente"
                />
              </b-form-group>
              <div class="mt-3" v-if="getUser.department_id !== 13">
                <h5 class="app-label section-label mb-1">
                  <span class="align-middle">Calendario</span>
                </h5>
                <div class="list-docts" v-if="!getUser.appointee_id">
                  <h2>Doctores</h2>
                  <b-form-group>
                    <b-form-checkbox-group
                        v-model="selectedCalendars"
                        name="event-filter"
                        stacked
                        @input="emitDoctor"
                    >
                      <b-form-checkbox
                          v-for="item in doctors"
                          :key="item.id"
                          name="event-filter"
                          :value="item.id"
                          class="mb-1"
                          :class="`custom-control-${item.color}`"
                      >
                        {{ item.label }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                  class="full-calendar"
                  ref="fullCalendar"
                  :options="calendarOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import {
  BAvatar,
  BBadge, BButton, BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable, VBTooltip,
  BPopover, BFormCheckbox, BFormCheckboxGroup
} from "bootstrap-vue";

export default {
  name: "NewCalendar",
  components: {
    FullCalendar,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip,
    BPopover, BFormCheckbox, BFormCheckboxGroup,ModalAppointment
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mounted() {
    this.$root.$on('closeComponent', async() => {
      // your code goes here
      await this.closeModal()
    })
  },
  data() {
    return {
      id_apoi: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        locale: esLocale,
        slotMinTime:"08:00:00",
        slotMaxTime:"22:00:00",
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        selectable: true,
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.updateAppointment,
        eventDidMount: this.renderFunction,
      },
      all_doctor: true,
      doctors: [],
      selectedCalendars: [],
      search_appointment:null
    };
  },
  async created() {
    if (this.getUser.appointee_id) {

      this.selectedCalendars.push(this.getUser.appointee_id)
      await this.initialize(this.selectedCalendars)
    } else {
      await this.doctorArray()
    }
  },
  methods: {
    ...mapActions('calendar_new', ['findCalendar', 'findAppointment','findCalendarSearch']),
    resetModal() {
    },
    async checkAll(type) {
      if (type) {
        this.all_doctor = type
        await this.doctorArray()
      } else {
        this.all_doctor = type
        this.selectedCalendars = []
        return
      }

    },
    async emitDoctor() {
      await this.initialize(this.selectedCalendars)

    },
    async findCalendarPlacesSearchCompoPatient() {
      this.calendarOptions.events = []
      if (!this.search_appointment){
        return await this.initialize(this.selectedCalendars)
      }
      const response = await this.findCalendarSearch({data: this.selectedCalendars,  search:this.search_appointment})
      this.calendarOptions.events = []
      for (const a of response) {
        const date1 = moment(a.app_dt_start).subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss')
        const date2 = moment(a.app_dt_stop).subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.calendarOptions.events.push({
          id: a.id,
          title: a.ResPartner.name,
          color: a.ResPartnerAppointment.color_hexa,
          start: date1,
          end: date2,
          extendedProps: {
            guests: {
              doctor_id: a.ResPartnerAppointment.id,
              reference: a.name,
              description: a.description,
              name_doctor: a.ResPartnerAppointment.name,
              type: a.ClinicTypeAppointment ? a.ClinicTypeAppointment.name : '' ,
            },
            calendar: a.ResPartnerAppointment.name,
            doctor: a.ResPartnerAppointment.id,

          },
        })
      }
    },
    async doctorArray() {
      const response = await this.findAppointment()
      this.doctors = []
      this.selectedCalendars = []

      for (const a of response) {
        this.doctors.push({
          color: 'primary',
          label: a.ResPartnerAppointment.name,
          id: a.ResPartnerAppointment.id,
        })
        this.selectedCalendars.push(a.ResPartnerAppointment.id)
      }
    },
    async colorState(color){
      if (color === "Primera Vez"){
        return '#235C95'
      }else if (color === "Subsecuente"){
        return '#0B9D27'
      }else{
          return '#CB4525'
      }
    },
    async initialize(data) {
      if (data.length <= 0) {
        this.calendarOptions.events = []
        return
      }
      this.calendarOptions.events = []
      const response = await this.findCalendar(data)
      for (const a of response) {
        const date1 = moment(a.app_dt_start).subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss')
        const date2 = moment(a.app_dt_stop).subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.calendarOptions.events.push({
          id: a.id,
          title: a.ResPartner.name,
          color:  a.ClinicTypeAppointment ? await this.colorState(a.ClinicTypeAppointment.name) : "#C8CB29" ,
          start: date1,
          end: date2,
          extendedProps: {
            guests: {
              doctor_id: a.ResPartnerAppointment.id,
              reference: a.name,
              description: a.description,
              name_doctor: a.ResPartnerAppointment.name,
              type: a.ClinicTypeAppointment ? a.ClinicTypeAppointment.name : '' ,
            },
            calendar: a.ResPartnerAppointment.name,
            doctor: a.ResPartnerAppointment.id,

          },
        })
      }
    },
    handleDateClick(arg) {
      let doct;
      if (this.getUser.appointee_id) {
        doct = 'doctor'
      } else {
        doct = 'recepcion'
      }
      this.$router.push(`/nueva/consulta/${doct}`)
    },
    async updateAppointment(info) {
      const id = info.event._def.publicId
      this.id_apoi = parseInt(id)
      await this.$bvModal.show('modal-prevent-closing')

    },
    async closeModal() {
      this.id_apoi = null
      await this.$bvModal.hide('modal-prevent-closing')
      await this.initialize(this.selectedCalendars)

    },
    renderFunction(info) {

      const event_start = moment(info.event.start).format('HH:mm');
      const event_end = moment(info.event.end).format('HH:mm');
      let titleStr = 'xxxx'
      let contentStr = 'xxxx'
      const html_view = `
           Horario de cita:    ${event_start} - ${event_end},
           Medico o sala: ${info.event._def.extendedProps.guests.name_doctor}
            Observaciones: ${info.event._def.extendedProps.guests.description}
            Tipo de cita: ${info.event._def.extendedProps.guests.type}
   `
      new BPopover({
        propsData: {
          container: 'body',
          html: true,
          title: info.event._def.title,
          content: `${html_view}`,
          triggers: 'hover',
          target: info.el,
        }
      }).$mount()
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
